
.breads {
  .btn-lg {
    position: relative;
    left: -6px;
    top: -1px;
  }

  .btn-arrow-right,
  .btn-arrow-left {
    position: relative;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 0 !important;
    margin-right: 1px;
  }
  .btn-arrow-right[disabled],
  .btn-arrow-left[disabled] {
    opacity: 1.00;
  }
  .btn-arrow-right:before, .btn-arrow-right:after,
  .btn-arrow-left:before,
  .btn-arrow-left:after {
    content: "";
    position: absolute;
    top: 4px;
    /* move it down because of rounded corners */
    height: 24px;
    /* button_inner_height / sqrt(2) */
    width: 24px;
    /* same as height */
    background: inherit;
    /* use parent background */
    border: inherit;
    /* use parent border */
    border-left-color: transparent;
    /* hide left border */
    border-bottom-color: transparent;
    /* hide bottom border */
    border-radius: 0 !important;
  }
  .btn-arrow-right:before,
  .btn-arrow-left:before {
    left: -13px;
  }
  .btn-arrow-right:after,
  .btn-arrow-left:after {
    right: -13px;
  }
  .btn-arrow-right.btn-arrow-left,
  .btn-arrow-left.btn-arrow-left {
    padding-right: 36px;
  }
  .btn-arrow-right.btn-arrow-left:before, .btn-arrow-right.btn-arrow-left:after,
  .btn-arrow-left.btn-arrow-left:before,
  .btn-arrow-left.btn-arrow-left:after {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
    /* rotate right arrow squares 45 deg to point right */
  }
  .btn-arrow-right.btn-arrow-right,
  .btn-arrow-left.btn-arrow-right {
    padding-left: 36px;
  }

  .btn-arrow-right.btn-arrow-right:before, .btn-arrow-right.btn-arrow-right:after,
  .btn-arrow-left.btn-arrow-right:before,
  .btn-arrow-left.btn-arrow-right:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    /* rotate right arrow squares 45 deg to point right */
  }

  .btn-arrow-right:after,
  .btn-arrow-left:before {
    /* bring arrow pointers to front */
    z-index: 3;
  }

  .btn-arrow-right:before,
  .btn-arrow-left:after {
    /* hide arrow tails background */
    background-color: #eef0f4;
  }

  /* Large */
  .btn-lg.btn-arrow-right,
  .btn-lg.btn-arrow-left,
  .btn-group-lg > .btn-arrow-left,
  .btn-group-lg > .btn-arrow-right {
    padding-left: 22px;
    padding-right: 22px;
    margin-right: 0px;
  }
  .btn-lg.btn-arrow-right:before, .btn-lg.btn-arrow-right:after,
  .btn-lg.btn-arrow-left:before,
  .btn-lg.btn-arrow-left:after,
  .btn-group-lg > .btn-arrow-left:before,
  .btn-group-lg > .btn-arrow-left:after,
  .btn-group-lg > .btn-arrow-right:before,
  .btn-group-lg > .btn-arrow-right:after {
    top: 6px;
    /* move it down because of rounded corners */
    height: 32px;
    /* button_inner_height / sqrt(2) */
    width: 32px;
    /* same as height */
  }
  .btn-lg.btn-arrow-right:before,
  .btn-lg.btn-arrow-left:before,
  .btn-group-lg > .btn-arrow-left:before,
  .btn-group-lg > .btn-arrow-right:before {
    left: -16px;
  }
  .btn-lg.btn-arrow-right:after,
  .btn-lg.btn-arrow-left:after,
  .btn-group-lg > .btn-arrow-left:after,
  .btn-group-lg > .btn-arrow-right:after {
    right: -16px;
  }
  .btn-lg.btn-arrow-right.btn-arrow-left,
  .btn-lg.btn-arrow-left.btn-arrow-left,
  .btn-group-lg > .btn-arrow-left.btn-arrow-left,
  .btn-group-lg > .btn-arrow-right.btn-arrow-left {
    padding-right: 44px;
  }
  .btn-lg.btn-arrow-right.btn-arrow-right,
  .btn-lg.btn-arrow-left.btn-arrow-right,
  .btn-group-lg > .btn-arrow-left.btn-arrow-right,
  .btn-group-lg > .btn-arrow-right.btn-arrow-right {
    padding-left: 44px;
  }

  /* Small */
  .btn-sm.btn-arrow-right,
  .btn-sm.btn-arrow-left,
  .btn-group-sm > .btn-arrow-left,
  .btn-group-sm > .btn-arrow-right {
    padding-left: 14px;
    padding-right: 14px;
    margin-right: -1px;
  }
  .btn-sm.btn-arrow-right:before, .btn-sm.btn-arrow-right:after,
  .btn-sm.btn-arrow-left:before,
  .btn-sm.btn-arrow-left:after,
  .btn-group-sm > .btn-arrow-left:before,
  .btn-group-sm > .btn-arrow-left:after,
  .btn-group-sm > .btn-arrow-right:before,
  .btn-group-sm > .btn-arrow-right:after {
    top: 4px;
    /* move it down because of rounded corners */
    height: 20px;
    /* button_inner_height / sqrt(2) */
    width: 20px;
    /* same as height */
  }
  .btn-sm.btn-arrow-right:before,
  .btn-sm.btn-arrow-left:before,
  .btn-group-sm > .btn-arrow-left:before,
  .btn-group-sm > .btn-arrow-right:before {
    left: -10px;
  }
  .btn-sm.btn-arrow-right:after,
  .btn-sm.btn-arrow-left:after,
  .btn-group-sm > .btn-arrow-left:after,
  .btn-group-sm > .btn-arrow-right:after {
    right: -10px;
  }
  .btn-sm.btn-arrow-right.btn-arrow-left,
  .btn-sm.btn-arrow-left.btn-arrow-left,
  .btn-group-sm > .btn-arrow-left.btn-arrow-left,
  .btn-group-sm > .btn-arrow-right.btn-arrow-left {
    padding-right: 28px;
  }
  .btn-sm.btn-arrow-right.btn-arrow-right,
  .btn-sm.btn-arrow-left.btn-arrow-right,
  .btn-group-sm > .btn-arrow-left.btn-arrow-right,
  .btn-group-sm > .btn-arrow-right.btn-arrow-right {
    padding-left: 28px;
  }

  /* Extra Small */
  .btn-xs.btn-arrow-right,
  .btn-xs.btn-arrow-left,
  .btn-group-xs > .btn-arrow-left,
  .btn-group-xs > .btn-arrow-right {
    padding-left: 10px;
    padding-right: 10px;
    margin-right: -1px;
  }
  .btn-xs.btn-arrow-right:before, .btn-xs.btn-arrow-right:after,
  .btn-xs.btn-arrow-left:before,
  .btn-xs.btn-arrow-left:after,
  .btn-group-xs > .btn-arrow-left:before,
  .btn-group-xs > .btn-arrow-left:after,
  .btn-group-xs > .btn-arrow-right:before,
  .btn-group-xs > .btn-arrow-right:after {
    top: 3px;
    /* move it down because of rounded corners */
    height: 14px;
    /* button_inner_height / sqrt(2) */
    width: 14px;
    /* same as height */
  }
  .btn-xs.btn-arrow-right:before,
  .btn-xs.btn-arrow-left:before,
  .btn-group-xs > .btn-arrow-left:before,
  .btn-group-xs > .btn-arrow-right:before {
    left: -7px;
  }
  .btn-xs.btn-arrow-right:after,
  .btn-xs.btn-arrow-left:after,
  .btn-group-xs > .btn-arrow-left:after,
  .btn-group-xs > .btn-arrow-right:after {
    right: -7px;
  }
  .btn-xs.btn-arrow-right.btn-arrow-left,
  .btn-xs.btn-arrow-left.btn-arrow-left,
  .btn-group-xs > .btn-arrow-left.btn-arrow-left,
  .btn-group-xs > .btn-arrow-right.btn-arrow-left {
    padding-right: 20px;
  }
  .btn-xs.btn-arrow-right.btn-arrow-right,
  .btn-xs.btn-arrow-left.btn-arrow-right,
  .btn-group-xs > .btn-arrow-left.btn-arrow-right,
  .btn-group-xs > .btn-arrow-right.btn-arrow-right {
    padding-left: 20px;
  }

  /* Button Groups */
  .btn-group > .btn-arrow-left:hover, .btn-group > .btn-arrow-left:focus,
  .btn-group > .btn-arrow-right:hover,
  .btn-group > .btn-arrow-right:focus {
    z-index: initial;
  }

  .btn-group > .btn-arrow-right + .btn-arrow-right,
  .btn-group > .btn-arrow-left + .btn-arrow-left {
    margin-left: 0px;
  }

  .btn-group > .btn:not(.btn-arrow-right):not(.btn-arrow-left) {
    z-index: 1;
  }
}

.nav-tab-trigger {
  line-height: 10px !important;
  font-weight: 300 !important;
  font-size: 13px !important;
}

.tab-pane {

  table {

    background: #fff;
  }
}

/* Breadcrumbs from http://bootsnipp.com/snippets/featured/triangle-breadcrumbs-arrows */
.btn-breadcrumb .btn:not(:last-child):after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 10px solid white;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: 100%;
  z-index: 3;
}

.btn-breadcrumb .btn:not(:last-child):before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 10px solid rgb(173, 173, 173);
  position: absolute;
  top: 50%;
  margin-top: -17px;
  margin-left: 1px;
  left: 100%;
  z-index: 3;
}

.btn-breadcrumb .btn {
  padding: 6px 12px 6px 24px;
}

.btn-breadcrumb .btn:first-child {
  padding: 6px 6px 6px 10px;
}

.btn-breadcrumb .btn:last-child {
  padding: 6px 18px 6px 24px;
}

/** Default button **/
.btn-breadcrumb .btn.btn-default:not(:last-child):after {
  border-left: 10px solid #fff;
}

.btn-breadcrumb .btn.btn-default:not(:last-child):before {
  border-left: 10px solid #ccc;
}

.btn-breadcrumb .btn.btn-default:hover:not(:last-child):after {
  border-left: 10px solid #ebebeb;
}

.btn-breadcrumb .btn.btn-default:hover:not(:last-child):before {
  border-left: 10px solid #adadad;
}

/* The responsive part */

.btn-breadcrumb > * > div {
  /* With less: .text-overflow(); */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-breadcrumb > *:nth-child(n+2) {
  display: none;
}

/* === For phones =================================== */
@media (max-width: 767px) {
  .btn-breadcrumb > *:nth-last-child(-n+2) {
    display: block;
  }
  .btn-breadcrumb > * div {
    max-width: 60px;
  }
}

/* === For tablets ================================== */
@media (min-width: 768px) and (max-width: 991px) {
  .btn-breadcrumb > *:nth-last-child(-n+4) {
    display: block;
  }
  .btn-breadcrumb > * div {
    max-width: 100px;
  }
}

/* === For desktops ================================== */
@media (min-width: 992px) {
  .btn-breadcrumb > *:nth-last-child(-n+6) {
    display: block;
  }
  .btn-breadcrumb > * div {
    max-width: 170px;
  }
}

.task-header {

  .task-panel {
    //padding:16px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .task-panel-main {
      width: 100%;
      padding: 16px;
      //min-height: 250px;

      @media only screen and (min-width: 768px) {
        width: 60%;
      }

      .task-description {
        padding: 0 10px 0 0px;
        line-height: 21px;
        font-size:14px;
        word-break: break-all;
        overflow: hidden;
      }
    }
    .task-panel-secondary {
      width: 100%;
      padding: 0;

      @media only screen and (min-width: 768px) {
        width: 40%;
        padding: 0 0 0 30px;
      }

      .task-panel-secondary-inside {
        padding: 16px;
        //min-height: 250px;

      }
    }
    #status-select {
      width: 50%;
    }
  }
  .status-select {
    margin: 10px 0;
  }

  .readmore{
    position: relative;
    top: 9px;
    font-size: 14px;
    width: 100%;
    display: block;
    text-align: right;
    &:hover{
      color:#000;
    }

    .more{
      //background: url('../images/down-arrow.png');
    }
  }

}

.task-bottom {
  display: flex;
  flex-wrap: wrap;
  .comments-container {
    width: 60%;
  }
  .task-sidebar {
    width: 40%;
    padding: 0 0 0 30px;
  }

  .sidebar-container {

    padding: 20px;

  }
  .no-content {
    text-align: center;
  }



}
.subtask-action{
  width:100%;
  text-align: right;
  opacity: 0;
}
ul.task-subtask-list{
  li{
    margin: 5px 0 20px 0;
    padding:0 0 20px 0;
    border-bottom: 1px solid #ccc;
    h6{
      margin:2px 0;
    }
    a{
      &:hover{
        color:#000;

      }
    }
  }
}


.pipeline-timeline{
  display: flex;
  li{
    display: flex;
    align-items: center;
    margin: 10px 1px 0;
  }
  .active{
    //font-size: 20px;
  }
}

/* Jony */

.task-single {

  .select2.select2-container {
    width: 80%;
  }

  .col-sm-6 {
    float: none;
    padding: 0;
  }

  .flex.center {
    display: flex;
    justify-content: center;
    margin: 10px auto 0;
  }

  .subtask-action {
    display: flex;
    justify-content: center;
    margin: 10px auto 0;
  }

  .task-bottom {

    .comments-container {
      width: 100%;

      @media only screen and (min-width: 768px) {
        width: 60%;
      }
    }

    .task-sidebar {
      width: 100%;
      padding: 0;

      @media only screen and (min-width: 768px) {
        width: 40%;
        padding: 0 0 0 30px;
      }
    }
  }
}

.help-block {
  display: none;
}
