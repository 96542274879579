.nav-toggle-custom{

  position: relative;
  top: 14px;
  left: 0px;
  font-size: 24px;

}
@media only screen and (min-width: 768px) {
  .nav-toggle-custom{
    left: 14px;
  }
}
