@import url(//fonts.googleapis.com/css?family=PT+Sans:400italic,400,700);
a {
  outline: none !important;
}
hr {
  border-top: 1px solid #e1e1e1;
}
.page-header {
  border-bottom-color: #e1e1e1;
}
.panel {
  border: 1px solid #eee;
  border-bottom: 1px solid #d1d2d3;
}
.panel .panel-heading a {
  text-decoration: none !important;
}
.well {
  border-bottom: 1px solid #d1d2d3;
}
.jumbotron {
  border-bottom: 2px solid #d1d2d3;
}
.nav-tabs > li > a {
  font-weight: bold;
  color: #656565;
  background-color: #f7f7f7;
  margin: 0;
  border: 1px solid #d1d2d3;
  border-radius: 0;
  padding: 10px 10px;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: inherit;
  border-bottom-color: #fff;
}
.tab-content {
  padding: 10px 20px 0px;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-color: #d1d2d3;
}
.nav-pills + .tab-content {
  border: 0;
  padding: 0;
}
.btn,
.fc-button {
  border-radius: 3px;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: 0 -1px 0px rgba(0, 0, 0, 0.15) inset;
  -webkit-appearance: none;
  outline: none !important;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}
.btn.btn-link {
  box-shadow: none;
  border: 0;
}
.btn-lg {
  box-shadow: 0 -2px 0px rgba(0, 0, 0, 0.15) inset;
}
.form-control,
.input-group-addon {
  border-color: #dbd9d9;
}
.form-control {
  box-shadow: 0 0 0 #000 !important;
}
.input-group-addon {
  background-color: #f8f9fb;
}
fieldset {
  padding-bottom: 20px;
  border-bottom: 1px dashed #eee;
  margin-bottom: 20px;
}
fieldset.last-child,
fieldset:last-child {
  border-bottom: 0;
}
fieldset .form-group {
  margin-bottom: 0;
}
.table > thead > tr > th {
  border-bottom-width: 1px;
}
.table > tbody + tbody {
  border-bottom-width: 1px;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 1px;
}
.progress {
  -webkit-box-shadow: 0 0 0 #000000;
  box-shadow: 0 0 0 #000000;
  border-radius: 3px;
  border: 1px solid #f1f1f1;
  background-color: #fff;
}
.progress .progress-bar {
  -webkit-box-shadow: 0 0 0 #000000;
  box-shadow: 0 0 0 #000000;
}
.popover {
  box-shadow: 0 0 0 #000;
  border-color: #eee;
  border-bottom: 2px solid #e1e1e1;
  border-radius: 3px;
}
.popover .popover-title {
  border: 0;
}
.nav li > a {
  background-color: transparent;
}
.nav.nav-pills .active > a {
  background-color: #3498db;
}
.dropdown-menu {
  border-radius: 3px;
}
.dropdown-header {
  color: #a1a2a3;
}
.carousel .carousel-indicators {
  bottom: 0;
}
.carousel .carousel-control.left,
.carousel .carousel-control.right {
  background-image: none;
}
.carousel .carousel-control em {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
}