

/* NAVEGACION TAREAS */

#crumbs {
  text-align: left;
}

#crumbs ul {
  margin:0;
  padding:0;
  list-style: none;
  display: inline-table;
}
#crumbs ul li {
  display: inline;
}

#crumbs ul li a {
  display: block;
  float: left;
  height: 70px;
  background: #3498db;
  text-align: center;
  padding: 22px 25px 0 60px;
  position: relative;
  margin: 0 5px 0 0;
  text-align:center;
  font-size: 14px;
  font-weight:bold;
  text-decoration: none;
  color: #fff;
}


#crumbs ul li a:before {
  content: "";
  border-top: 35px solid transparent;
  border-bottom: 35px solid transparent;
  border-left: 35px solid #eee;
  position: absolute; left: 0; top: 0;
}

#crumbs ul li:first-child a {
  border-top-left-radius: 0; border-bottom-left-radius: 0;
}
#crumbs ul li:first-child a:before {
  display: none;
}

#crumbs ul li:last-child a {
  padding-right: 80px;
  border-top-right-radius: 0; border-bottom-right-radius: 0;
}
#crumbs ul li:last-child a:after {
  /*display: none; */
}

#crumbs ul li a:hover {
  background: #444 !important;
}
#crumbs ul li a:hover:after {
  border-left-color: #444 !important;
}


#crumbs ul li a.ingreso{
  background-color:#EE7373;
}

#crumbs ul li a.ingreso:after {
  border-left-color: #EE7373;
}

#crumbs ul li a.aprobacion{
  background-color:#6597AA;
}
#crumbs ul li a.aprobacion:after {
  border-left-color: #6597AA;
}

#crumbs ul li a.correccion{
  background-color:#88BE84;
}
#crumbs ul li a.correccion:after {
  border-left-color: #88BE84;
}

#crumbs ul li a.edicion{
  background-color:#84BEB1;
}
#crumbs ul li a.edicion:after {
  border-left-color: #84BEB1;
}

#crumbs ul li a.untref{
  background-color:#D3517A;
}
#crumbs ul li a.untref:after {
  border-left-color: #D3517A;
}

#crumbs ul li a.finalizado{
  background-color:#EE2916;
}
#crumbs ul li a.finalizado:after {
  border-left-color: #EE2916;
}

/* JONY */
.row {
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: auto;
  }
}

.nav.navbar-nav.navbar-right {
  float: left !important;
  width: 100%;
  margin-top: 30px;
  margin-left: 0px;

  @media only screen and (min-width: 768px) {
    float: right !important;
    width: auto;
    margin-top: 0px;
    margin-left: 0px;
  }
}

.nav.nav-tabs, .tit-not {
  display: none;

  @media only screen and (min-width: 768px) {

    display: block;
  }
}

.btn-noti {
  position:absolute;
  top: 17px;
  right:20px;
  display: flex;

  @media only screen and (min-width: 768px) {
    right:200px;
  }

  .tit-not, i.fa-bell-o {
    margin-right: 5px;
    font-size: 20px;

    @media only screen and (min-width: 768px) {
      font-size: 14px;
    }
  }

  .badge {
    position: absolute;
    right: -5px;
    top: -10px;
  }
}

.functional-unit {

  .btn-group {
    display: flex;

    .btn {
      color: white;
      background-color: #3498db;
    }

    .dropdown-menu {
      width: 100%;
      padding: 0;

      li {

        a {
          font-size: 18px !important;
          padding: 15px 20px;
          text-align: center;
          border-bottom: thin solid rgba(255,255,255,0.5);
        }
      }
    }
  }
}

.menu-search {
  width: 65%;
  margin-top: 2% !important;

  @media only screen and (min-width: 768px) {
    margin-top: 0.55% !important;
  }

  form {
    display: flex;
  }
}

/* */

.nav.nav-tabs li{
  margin-right:5px;
  .nav-tab-trigger{
    box-shadow: inset 0 10px 20px -10px rgba(0,0,0,0.5);
    transition: ease 0.25s;

    &.active, &:hover{
      //color: #000 !important;
      padding-bottom: 15px;
      margin-top: -5px;
      position: relative;
      box-shadow: inset 0 -10px 20px -10px rgba(0,0,0,0.5);
      // bottom: 5px;
    }
  }
}


.nav.nav-tabs > li > a {
  padding: 10px 5px;

  @media screen and (min-width: 1280px) {
    padding: 10px;
  }
}

.nav.nav-tabs li a{
  background-color:#ccc;
  border-top-left-radius:5px;
  border-top-right-radius:5px;
  color:#fff;
}

.nav.nav-tabs li a:hover{
  background-color:#444;
}

.nav.nav-tabs li a.ingreso{
  background-color:#EE7373;
  border:1px solid #EE7373;
}

.nav.nav-tabs li a.aprobacion{
  background-color:#6597AA;
  border:1px solid #6597AA;
}

.nav.nav-tabs li a.correccion{
  background-color:#88BE84;
  border:1px solid #88BE84;
}

.nav.nav-tabs li a.edicion{
  background-color:#84BEB1;
  border:1px solid #84BEB1;
}

.dropdown {
  display: block;

  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.comentarios{
  padding:0px 5px 0px 0px;
  margin:0 0 10px 0;
  overflow:auto;
}

.comentarios li{
  list-style:none;
  background-color:#ddd;
  padding:10px;
  margin-bottom:5px;
  border-bottom:4px solid #ccc;
}

.comentarios li hr{
  border-bottom:1px solid #ccc;
  margin:10px 0 15px 0;
}

#laravelista-comments .media-body p {
  line-height: 1.5;
}
