.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.wrapper:before,
.wrapper:after {
  content: " ";
  display: table;
}
.wrapper:after {
  clear: both;
}
.wrapper.wrapper-boxed {
  max-width: 960px;
  margin: 0 auto;
}
.wrapper > .aside {
  position: absolute;
  margin-top: 120px;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  z-index: 10;
}
.wrapper > section {
  position: relative;
  background-color: #eef0f4;
  min-height: 100%;
  z-index: 11;
  margin-left: 0;
}
.main-content {
  padding: 20px 10px;
}
.main-content > h3 {
  margin: 0 0 30px 0;
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .wrapper > .aside {
    position: fixed;
    z-index: 501;
    margin-top: 50px;
  }
  .wrapper > section {
    margin-left: 250px;
    padding-top: 60px;
  }
  .main-content {
    padding: 20px 40px;
  }
}
.aside-toggled {
  overflow-y: hidden;
}
.aside-toggled .wrapper > section {
  margin-left: 250px;
}
.csstransforms3d .wrapper > section {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.csstransforms3d .aside-toggled .wrapper > section {
  margin-left: 0;
  -webkit-transform: translate3d(250px, 0, 0);
  transform: translate3d(250px, 0, 0);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media only screen and (min-width: 768px) {
  .aside-toggled {
    overflow-y: auto;
  }
  .aside-toggled .wrapper > .aside {
    width: 70px;
  }
  .aside-toggled .wrapper > section {
    margin-left: 70px;
  }
  .csstransforms3d .aside-toggled .wrapper > section {
    -webkit-transform: none;
    -moz-transform: none;
    -opera-transform: none;
    -ms-transform: none;
    transform: none;
    margin-left: 70px;
  }
}