.default-form{
  width:50%;

  label{
    font-weight: 300;
    margin: 10px 0 10px 0;
  }
  input:not([type="submit"]) {
    width:100%;
  }

  input[type="submit"]{
    margin:30px 0 0 0;
  }

  @media only screen and (max-width:40em) {
  width:100%;
  }

}