//.laravelista-comments{
//  display: none;
//  .lead{
//    font-size: 14px!important;
//  }
//}

@media (min-width: 768px) {
  #laravelista-comments {
    .lead {
      font-family: "PT Sans", sans-serif;
      color: #656565;
      //border: 1px solid #eee;
      font-size: 16px !important;
      padding: 16px;
      margin-bottom: 20px;
      margin-top: 1px;

      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 4px;
      -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
      box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
      border-bottom: 1px solid #d1d2d3;

    }

    .media-body {
      p {
        font-size: 14px;

      }

      .unit-element{
       display:inline-block;
      }
      .unit-comment{
        color:transparent;
        width:7px;
      }
      .unit-comment::before {
        content: '\25ba';
        color: rgba(73, 71, 72, 0.67);
        position: relative;
        top:0px;
        font-size:10px;
        padding-right: 0.5em;
      }
    }
  }

}
