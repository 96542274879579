
// Fonts
@import url('//fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');

// Variables
@import "variables";

// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


body {

  &.csstransforms3d {
    padding-top: 0;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-content {
  width: 300px;
}

.login-content h1 {
  font-weight: 200;
}

.login-content input {
  height: 30px;
  font-size: 17px;
  font-weight: 100;
  width: 100%;
  text-align: left;
}

body,
html {
  height: 100%;
  font-size: 14px;
}

body {
  font: 15px/1 'Open Sans', sans-serif;
  color: #777;
}

a {
  cursor: pointer;
  word-break: break-all;
}

a:hover {
  color: #ccc;
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}


table{
  font-size: 14px;
}

@import "parts/reset";
@import "../../../node_modules/font-awesome/css/font-awesome.css";


@import "layouts/app";
@import "martin";
@import "parts/login";

@import "parts/task";
@import "parts/comments";
//@import "parts/datetimepicker";
@import "parts/select2";
@import "parts/summernote";
@import "parts/selec2theme";
@import "parts/forms";
@import "parts/main";
